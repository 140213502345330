import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Container, Stack } from '@mui/material';

import Background from './Background';
import Footer from './Footer';
import Header from './Header';


export function Layout() {
  return (
    <>
      <Background />
      <Stack
        direction="column"
        spacing={2}
        sx={{
          minHeight: '100vh',
        }}>
        <Header />
        <Box sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
        <Footer />
      </Stack>
    </>
  );
}

export default Layout;
