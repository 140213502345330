import React, { useEffect } from 'react'
import { Container, Box } from '@mui/material';

import MarkdownViewer from '../markdown';

export function Terms() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [])

  return (
    <>
      <Container>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flexGrow: 1,
        }}>
          <MarkdownViewer file={'/md/terms_of_service.md'} />
        </Box>
      </Container>
    </>
  )
}

export default Terms