import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// import { Preloader } from './components/Preloader';
// landing
import Layout from './components/Layout';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Landing from './pages/Landing';
import App from './pages/App';

// app
// import { ComingSoon } from './components/ComingSoon';
// import { Start } from './components/App/Start';
// import { Privacy } from './components/App/Privacy';
// import { SignIn } from './components/App/SignIn';
// import { PayWall } from './components/App/PayWall';
// import { Onboarding } from './components/App/Onboarding';
// import { Chats } from './components/App/Chats';
// import { Home } from './components/App/Home';
// import { Profile } from './components/App/Profile';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "privacy",
        element: <Privacy />,
      },
      {
        path: "*",
        element: <Landing />,
      },
    ],
  },
  {
    path: "/app",
    element: <Layout />,
    children: [
      {
        path: "",
        element: (<App />),
      },
      // {
      //   path: "/",
      //   element: (<Start />),
      // },
      // {
      //   path: "/privacy",
      //   element: (<Privacy />),
      // },
      // {
      //   path: "/signin",
      //   element: (<SignIn />),
      // },
      // {
      //   path: "/paywall",
      //   element: (<PayWall />),
      // },
      // {
      //   path: "/onboarding",
      //   element: (<Onboarding />),
      // },
      // {
      //   path: "/home",
      //   element: (<Home />),
      // },
      // {
      //   path: "/chats",
      //   element: (<Chats />),
      // },
      // {
      //   path: "/profile",
      //   element: (<Profile />),
      // },
      {
        path: "*",
        element: (<App />),
      },
    ],
  }
]);

export function Router() {
  return (
    <RouterProvider router={router} />
  );
}

export default Router