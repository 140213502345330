import { motion } from 'framer-motion';
import { Box, Typography, Link } from '@mui/material';
import chat42 from '../assets/logoVertical.svg';

export function App() {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        flexGrow: 1,
      }}
    >
      <Box sx={{
        position: 'relative',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
      }}>

        <Box sx={{
          mt: '15vh',
        }}>
          <img
            src={chat42}
            style={{ height: '20vmin', width: 'auto' }}
            alt="chat42 logo vertical"
          />
        </Box>

        <Box width={'100%'}>
          <Box mt={'12vh'} sx={{
            width: '100%',
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}>
            <Typography variant="h4" sx={{
              fontSize: '3.2vmin',
              zIndex: 1,
            }}>
              Coming Soon
            </Typography>
          </Box>
        </Box>

      </Box>
    </Box >
  );
}

export default App