import React, { useEffect, useState, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import { motion, AnimatePresence } from 'framer-motion';
import { RecoilRoot, useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';

// import 'overlayscrollbars/overlayscrollbars.css';
// import {
//   OverlayScrollbars,
//   ScrollbarsHidingPlugin,
//   SizeObserverPlugin,
//   ClickScrollPlugin
// } from 'overlayscrollbars';

import "@fontsource/inter/300.css"; // Specify weight
import "@fontsource/inter/400.css"; // Specify weight
import "@fontsource/inter/500.css"; // Specify weight
import "@fontsource/inter/700.css"; // Specify weight
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { getDesignTokens } from './theme';
import { Preloader } from './components/Preloader';
import {
  appearanceState,
} from './state';

function Main() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const systemAppearance = prefersDarkMode ? 'dark' : 'light';

  const [appearance, setAppearance] = useRecoilState(appearanceState);
  const [initialized, setInitialized] = useState(false);

  const theme = useMemo(() => {
    const design = getDesignTokens(appearance);
    const resultTheme = createTheme(design);
    // console.log('mui theme', resultTheme);
    return resultTheme;
  }, [appearance]);

  useEffect(() => {
    setAppearance(systemAppearance);
  }, [systemAppearance, setAppearance]);

  useEffect(() => {
    // setInitialized(true)
    setTimeout(() => {
      setInitialized(true)
    }, 120);
  }, []);

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AnimatePresence>
          {!initialized ? (
            <Preloader key="preloader" />
          ) : (
            <App />
          )}
        </AnimatePresence>
      </ThemeProvider>
    </React.StrictMode>
  );
}

// const osInstance = OverlayScrollbars(document.querySelector('body'), {
//   scrollbars: {
//     theme: 'os-theme-dark', //appearance === 'dark' ? 'os-theme-light' : 'os-theme-dark',
//     // autoHide: 'scroll'
//   }
// });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RecoilRoot><Main /></RecoilRoot>);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
