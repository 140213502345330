
import './App.css';
import { Box } from '@mui/material';

import Router from './Router';

function App() {
  return (
    <Box className="App">
      <Router />
    </Box>
  );
}

export default App;
