import React, { useRef, useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom";
import { Container, Box, Stack, Typography, useTheme, useMediaQuery } from '@mui/material';

import './marquee.css'
import card1 from '../assets/cards/card1.png'
import card2 from '../assets/cards/card2.png'
import card3 from '../assets/cards/card3.png'
import card4 from '../assets/cards/card4.png'
import card5 from '../assets/cards/card5.png'
import card6 from '../assets/cards/card6.png'
import card7 from '../assets/cards/card7.png'
import card8 from '../assets/cards/card8.png'

const images = [
  { src: card1, alt: 'Expert Card' },
  { src: card2, alt: 'Expert Card' },
  { src: card3, alt: 'Expert Card' },
  { src: card4, alt: 'Expert Card' },
  { src: card5, alt: 'Expert Card' },
  { src: card6, alt: 'Expert Card' },
  { src: card7, alt: 'Expert Card' },
  { src: card8, alt: 'Expert Card' },
];

const Marquee = ({ images }) => {
  const navigate = useNavigate();
  const marqueeRef = useRef(null);
  const theme = useTheme();
  const isXl = useMediaQuery(theme.breakpoints.up('xl'));
  const isLg = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isMd = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isSm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const bp = isXl ? 'xl' : isLg ? 'lg' : isMd ? 'md' : isSm ? 'sm' : 'xs';
  console.log('current breakpoint', bp);

  const [marqueeWidth, setMarqueeWidth] = useState(0);
  const allImages = [...images, ...images];

  useEffect(() => {
    // Вычисляем общую ширину всех изображений
    let totalWidth = 0;
    const images = marqueeRef.current.children;
    for (let img of images) {
      totalWidth += img.offsetWidth;
      console.log('img.offsetWidth', img.offsetWidth);
    }
    console.log('totalWidth', totalWidth);
    setMarqueeWidth(totalWidth);
  }, [bp, navigate]);

  return (
    <Box
      sx={{
        overflow: 'hidden',
      }}>
      <Box className="image-marquee"
        ref={marqueeRef}
        style={{
          width: `${marqueeWidth}px`,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          display: 'flex',
          animation: 'marquee 15s linear infinite',
        }}
      >
        {allImages.map((image, index) => (
          <Box
            component="img"
            key={index}
            src={image.src}
            alt={image.alt}
            sx={{
              flex: 0,
              height: { xs: '16rem', },
              width: { xs: '14ewm', },
              px: { xs: '1rem', },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export function ExpertCards() {
  return (
    <>
      <Box sx={{ }}>
        <Container sx={{
          mb: '4vh',
          flexGrow: 1,
        }}>
          <Typography variant="h3">
            Converse with the Future
          </Typography>
        </Container>

        <Marquee images={images} />
      </Box>
    </>
  )
}

export default ExpertCards