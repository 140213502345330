import React from 'react'
import { Container, Box, Stack, Typography, Link, Paper } from '@mui/material';

// import windowsBadge from '../assets/badge-windows-version.png'
// import windows32Badge from '../assets/badge-windows-x86.png'
// import macosBadge from '../assets/badge_macos.png'
// import macosUniversalBadge from '../assets/badge_macos_universal.png'
// import macosArmBadge from '../assets/badge_macos_applesilicon.png'
import appStoreBadge from '../assets/badge_ios.svg'
import googlePlayBadge from '../assets/google-play-badge.png'

export function Download() {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={1}
        sx={{
          py: 2,
          flexWrap: 'wrap'
          // backgroundColor: '#00000022',
        }}
      >
        <a
          href='#'
          target='_blank'
          rel='noopener noreferrer'>
          <Box
            component="img"
            sx={{
              height: { xs: '3.3rem', sm: '4rem', md: '4rem', lg: '5rem', xl: '5rem' },
            }}
            src={googlePlayBadge}
            alt='Get on Google Play'
          />
        </a>

        <a
          href='https://apps.apple.com/us/app/chat42-personal-ai-assistant/id6477162040'
          target='_blank'
          rel='noopener noreferrer'>
          <Box
            component="img"
            sx={{
              height: { xs: '3.3rem', sm: '4rem', md: '4rem', lg: '5rem', xl: '5rem' },
            }}
            src={appStoreBadge}
            alt='Get on App Store'
          />
        </a>

      </Stack>
    </>
  )
}

export default Download