import React, { useEffect } from 'react'
import { Box, Container} from '@mui/material';

import MarkdownViewer from '../markdown';

export function Privacy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [])

  return (
    <>
      <Container>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          // px: 4,
        }}>
          <MarkdownViewer file={'/md/privacy_policy.md'} />
        </Box>
      </Container>
    </>
  )
}

export default Privacy