import React, { useState, useEffect } from 'react';
import Markdown from 'marked-react';
import './index.css'

function MarkdownViewer({ file }) {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch(file)
      .then((res) => res.text())
      .then((md) => {
        setContent(md);
      });
  }, [file]);

  return <Markdown>{content}</Markdown>
}

export default MarkdownViewer;
